import moment from "moment";
import "moment/locale/es";
moment.locale("es");

export default {
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    // Format date DD/MM/YYYY
    formatDateL(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.substr(0, 2)}/${month}/${year}`;
    },
    formatDateLWithTime(date) {
      if (!date) return null;

      const newDate = new Date(date);
      const dateToTimestamp = new Date(newDate);

      const [year, month, day] = date.split("-");
      const hours = `${dateToTimestamp.getHours() < 10 ? "0" : ""}${dateToTimestamp.getHours()}`;
      const minutes = `${dateToTimestamp.getMinutes() < 10 ? "0" : ""}${dateToTimestamp.getMinutes()}`;
      const seconds = `${dateToTimestamp.getSeconds() < 10 ? "0" : ""}${dateToTimestamp.getSeconds()}`;

      return `${day.substr(0, 2)}/${month}/${year}` + " - " + hours + ":" + minutes + ":" + seconds;
    },
    formatDateToISO8601(date) {
      return new Date(date).toISOString();
    },
    dateNowFormated() {
      return moment().format();
    },
    dateNextDayFormated() {
      return moment().add(1, "days").format();
    },
    date30DaysBeforeToday(date) {
      if (moment(date).isBefore(moment().subtract(30, "days"))) return moment().subtract(30, "days").format();
      else return date;
    }
  }
};
